(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _contactForm = require("./components/_contact-form");

var _scrollToTopButton = require("./components/_scroll-to-top-button");

var _testimonialsCarousel = require("./components/_testimonials-carousel");

window.SendEmail = _contactForm.SendEmail;
window.ScrollToTop = _scrollToTopButton.ScrollToTop;
window.TestimonialsCarousel = _testimonialsCarousel.TestimonialsCarousel;
(0, _scrollToTopButton.ScrollToTop)();
(0, _testimonialsCarousel.TestimonialsCarousel)();

},{"./components/_contact-form":2,"./components/_scroll-to-top-button":3,"./components/_testimonials-carousel":4}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SendEmail = void 0;

var SendEmail = function SendEmail() {
  var subject = document.getElementById("contactEmailSubject").value;
  var body = document.getElementById("contactEmailBody").value;
  var uri = "mailto:a.lagodzia@gmail.com?subject=";
  uri += encodeURIComponent(subject);
  uri += "&body=";
  uri += encodeURIComponent(body);
  window.open(uri);
};

exports.SendEmail = SendEmail;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrollToTop = void 0;

var ScrollToTop = function ScrollToTop() {
  var scrollTopButton = document.getElementById("btn-back-to-top");

  function showHideButton() {
    if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600) {
      scrollTopButton.style.display = "block";
    } else {
      scrollTopButton.style.display = "none";
    }
  }

  window.addEventListener("scroll", showHideButton);
  scrollTopButton.addEventListener("click", ToTop);

  function ToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
};

exports.ScrollToTop = ScrollToTop;

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TestimonialsCarousel = void 0;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var TestimonialsCarousel = function TestimonialsCarousel() {
  document.addEventListener("DOMContentLoaded", function () {
    var testimonialsCarousel = document.getElementById('testimonialsCarousel');

    if (testimonialsCarousel === null) {
      return;
    } // Get all carousel items


    var carouselItems = document.getElementsByClassName('carousel-item'); // Convert the collection to an array for easier manipulation

    var itemsArray = Array.prototype.slice.call(carouselItems);
    console.debug('Find the maximum height among all items');
    var maxHeight = Math.max.apply(Math, _toConsumableArray(itemsArray.map(function (item) {
      var height = item.offsetHeight;

      if (height === 0) {
        height = getHeight(testimonialsCarousel.querySelector('.carousel-inner'), item);
      }

      console.debug('Item height: ' + height);
      return height;
    })));
    console.debug('Max height is: ' + maxHeight);
    var testimonials = document.querySelectorAll('.testimonial');
    console.debug('Aligning height of other testimonials to the highest one.');

    for (var i = 0; i < testimonials.length; i++) {
      if (testimonials[i].style.offsetHeight !== maxHeight) {
        testimonials[i].style.height = maxHeight + 'px';
      }
    }

    function getHeight(parent, carouselItem) {
      var carouselItemCloned = carouselItem.cloneNode(true);
      parent.appendChild(carouselItemCloned);
      carouselItemCloned.style.display = "block";
      var height = carouselItemCloned.offsetHeight;
      carouselItemCloned.style.display = "none";
      parent.removeChild(carouselItemCloned);
      return height;
    }
  });
};

exports.TestimonialsCarousel = TestimonialsCarousel;

},{}]},{},[1]);
